import $ from 'jquery';
import { intervalToDuration } from 'date-fns';

export const buildHealthIndicationInterval = ({ fromX, toX, content, type, time, duration_s, height }) => {
  const chartHeight = height || 130;
  const top = 0;

  const isPartialLeft = fromX < 0;
  const isPartialRight = toX > 1100;

  const left = isPartialLeft ? 0 : fromX;
  const right = isPartialRight ? 1100 : toX;

  const width = right - left;

  const duration = intervalToDuration({
    start: 0,
    end: duration_s * 1000,
  });

  const container = $('<div></div>').css({
    top,
    left,
    width,
    height: chartHeight,
    position: 'absolute',
  });

  const tooltip = $(
    `<span class="tooltiptext"><span>${type}</span><br><span>${time}</span><br><span>${content}</span><br><span>Duration: ${duration.hours}h ${duration.minutes}m ${duration.seconds}s</span></span>`,
  ).css({
    visibility: 'hidden',
    width: '180px',
    backgroundColor: '#555',
    color: '#fff',
    textAlign: 'center',
    padding: '5px 0',
    borderRadius: '6px',

    /* Position the tooltip text */
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    marginLeft: '-90px',
    zIndex: 2,

    /* Fade in tooltip */
    opacity: 0,
    transition: 'opacity 0.3s',
  });

  const ruler = $(`<div></div>`).css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(235, 231, 99, 0.762)',
  });

  const rulerContent = $(`<div>HI</div>`).css({
    border: '1px solid',
    background: 'white',
    textAlign: 'center',
  });

  ruler.append(rulerContent);

  container
    .append(ruler)
    .append(tooltip)
    .on('mouseenter', () => {
      $(tooltip).css({
        visibility: 'visible',
        opacity: 1,
      });
    })
    .on('mouseleave', () => {
      $(tooltip).css({
        visibility: 'hidden',
        opacity: 0,
      });
    });

  container.addClass('uplot-additional-element');

  return container;
};
