import { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import useSessionStorage from 'hooks/useSessionStorage';

export const RecordingUpload = ({
  file,
  accessToken,
  uploadInProgress,
  lineNumber,
  status,
  error,
  onCreateRecording,
  onDeleteRecording,
  onUploadStatusChange,
}) => {
  const [selectedPatientId] = useSessionStorage<string>('selectedPatientId', '');

  useEffect(() => {
    const uploadFile = async () => {
      let createdRecordingId;

      try {
        // Create a recording entry in the database and get the upload URL
        const response = await onCreateRecording({
          variables: {
            createMyRecordingChannelCount: 1,
          },
        });
        const {
          data: {
            createMyRecording: { recordingId, ecgRecordingUploadUrl: uploadUrl },
          },
        } = response;

        createdRecordingId = recordingId;

        if (!uploadUrl) {
          throw new Error('Upload URL not returned');
        }

        const formData = new FormData();

        formData.append('ECGRecordingFile', file, file.name);
        formData.append('fileName', file.name);

        const uploadResponse = await fetch(uploadUrl, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ...(selectedPatientId ? { 'x-selected-patient-id': selectedPatientId } : {}),
          },
        });

        if (!uploadResponse.ok) {
          const errorMessage = await uploadResponse.text();

          throw new Error(errorMessage || 'Upload failed');
        }

        onUploadStatusChange(file, 'Uploaded');
      } catch (processError: any) {
        onUploadStatusChange(file, 'Failed', processError.message);

        // Optionally delete the recording entry if the upload fails
        if (createdRecordingId) {
          onDeleteRecording({
            variables: {
              deleteRecordingRecordingId: createdRecordingId,
            },
          });
        }
      }
    };

    if (uploadInProgress && status === 'Pending') {
      onUploadStatusChange(file, 'Uploading');
      uploadFile();
    }
  }, [
    file,
    accessToken,
    uploadInProgress,
    status,
    onCreateRecording,
    onDeleteRecording,
    onUploadStatusChange,
    selectedPatientId,
  ]);

  return (
    <ListItem>
      {lineNumber}. {file.name} - {status}
      {error && <div style={{ paddingLeft: 8 }}>| Error: {error}</div>}
    </ListItem>
  );
};
