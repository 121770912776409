import React from 'react';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import useSessionStorage from 'hooks/useSessionStorage';

declare global {
  interface Window {
    REACT_PUBLIC_SERVER_ENV: {
      graphqlUrl: string;
      AUTH0_DOMAIN: string;
      AUTH0_CLIENT_ID: string;
      AUTH0_AUDIENCE: string;
    };
  }
}

const GRAPHQL_URL =
  window.REACT_PUBLIC_SERVER_ENV.graphqlUrl ||
  process.env.REACT_APP_GRAPHQL_URL ||
  'http://localhost:4000/graphql';

const createClient = (selectedPatientId: string, getAccessTokenSilently: () => Promise<string>) => {
  const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
  });

  const authLink = setContext(async (_, { headers }) => {
    const sharedToken = window.localStorage.getItem('sharedToken');

    if (sharedToken) {
      return {
        headers: {
          ...headers,
          Authorization: sharedToken,
        },
      };
    }

    const loginToken = window.localStorage.getItem('loginToken');
    const token = loginToken ?? (await getAccessTokenSilently());

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        ...(selectedPatientId ? { 'x-selected-patient-id': selectedPatientId } : {}),
      },
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getMyRecordingsPaginated: {
            keyArgs: false,
            merge(existing, incoming) {
              // Always replace the existing recordings with the new ones
              // This solves the issue of the cache not updating when a recording is deleted
              return incoming;
            },
          },
        },
      },
    },
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    connectToDevTools: true,
  });
};

export const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedPatientId] = useSessionStorage<string>('selectedPatientId', '');

  const client = React.useMemo(
    () => createClient(selectedPatientId, getAccessTokenSilently),
    [selectedPatientId, getAccessTokenSilently],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
