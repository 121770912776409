import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Recording, RecordingFlag } from 'types/schema.type';
import { Button } from '@material-ui/core';
import { GET_ENUMS, MY_RECORDINGS } from '../queries';
import { REANALYZE_RECORDING, DELETE_RECORDING } from '../mutations';

import { RecordingItem } from './RecordingItem';
import { LoadingSpinner } from './LoadingSpinner';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  row: {
    '& > th': {
      fontWeight: 'bold',
    },
  },
  container: {
    marginTop: theme.spacing(2),
  },
  flagText: {
    paddingRight: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  flagCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reanalyze: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  filterToolbar: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  datePicker: {
    marginRight: theme.spacing(2),
  },
  filterExplanation: {
    marginRight: theme.spacing(2),
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    textAlign: 'center',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        disabled={page === 0}
        aria-label="first page"
        onClick={handleFirstPageButtonClick}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton disabled={page === 0} aria-label="previous page" onClick={handleBackButtonClick}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        onClick={handleNextButtonClick}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        onClick={handleLastPageButtonClick}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

type QueryGetMyRecordingsResponse = {
  getMyRecordingsPaginated: {
    success: boolean;
    error: string;
    totalCount: number;
    recordings: Recording[];
  };
};

export function RecordingList() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState<RecordingFlag | undefined>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Date filter state for "From" and "To"
  const [fromDate, setFromDate] = React.useState<number | null>(null);
  const [toDate, setToDate] = React.useState<number | null>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  /**
   * Queries
   */
  const {
    loading: myRecordingsLoading,
    error: myRecordingsError,
    data: myRecordingsData,
    fetchMore,
  } = useQuery<QueryGetMyRecordingsResponse>(MY_RECORDINGS, {
    variables: {
      offset: 0,
      limit: rowsPerPage,
      from: fromDate || null,
      to: toDate || null,
    },
  });

  const { data: enumData } = useQuery(GET_ENUMS, {
    variables: { enumName: 'RecordingFlag' },
  });

  /**
   * Mutations
   */
  const [reanalyze] = useMutation(REANALYZE_RECORDING, {
    refetchQueries: () => [
      {
        query: MY_RECORDINGS,
        variables: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          from: fromDate || null,
          to: toDate || null,
        },
      },
    ],
  });
  const [deleteRecording] = useMutation(DELETE_RECORDING, {
    refetchQueries: () => [
      {
        query: MY_RECORDINGS,
        variables: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          from: fromDate || null,
          to: toDate || null,
        },
      },
    ],
  });

  if (myRecordingsLoading) {
    return (
      <div className={classes.container}>
        <LoadingSpinner />
      </div>
    );
  }

  if (myRecordingsError) {
    return (
      <div className={classes.container}>Failed to load recording data: {myRecordingsError}</div>
    );
  }

  // Pass typecheck
  if (!myRecordingsData?.getMyRecordingsPaginated?.success) {
    return <div className={classes.container}>Failed to load recordings</div>;
  }

  /**
   * Handlers
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    handleClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const newOffset = newPage * rowsPerPage;

    fetchMore({
      variables: {
        offset: newOffset,
        limit: rowsPerPage,
        from: fromDate || null,
        to: toDate || null,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;

        // Replace the previous result with the newly fetched page
        return fetchMoreResult;
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handlers for date filters
  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date ? new Date(date).getTime() : null);
  };

  const handleToDateChange = (date: Date | null) => {
    setToDate(date ? new Date(date).getTime() : null);
  };

  const handleApplyFilter = () => {
    setPage(0);
    fetchMore({
      variables: {
        offset: 0,
        limit: rowsPerPage,
        from: fromDate || null,
        to: toDate || null,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => fetchMoreResult || prevResult,
    });
  };

  const handleClearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setPage(0);
    fetchMore({
      variables: {
        offset: 0,
        limit: rowsPerPage,
        from: null,
        to: null,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => fetchMoreResult || prevResult,
    });
  };

  const flagOptions: string[] = enumData?.__type?.enumValues?.map(({ name }) => name) ?? [];

  const { recordings, totalCount } = myRecordingsData.getMyRecordingsPaginated;

  // Filtering by flag (date filtering can be applied later when passing to the query)
  const filteredRecordings = recordings.filter((rec) =>
    !search ? true : rec.flags?.includes(search),
  );

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} maxWidth={false}>
        {/* Filter toolbar with explanation, DateTimePickers, and an Apply Filter button */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box className={classes.filterToolbar}>
            <Typography variant="subtitle1" className={classes.filterExplanation}>
              Filter recordings by
              <br />
              start date and time:
            </Typography>
            <DateTimePicker
              label="From"
              value={fromDate}
              format="yyyy/MM/dd HH:mm"
              className={classes.datePicker}
              clearable
              onChange={handleFromDateChange}
            />
            <DateTimePicker
              label="To"
              value={toDate}
              format="yyyy/MM/dd HH:mm"
              className={classes.datePicker}
              clearable
              onChange={handleToDateChange}
            />
            <Button variant="contained" color="primary" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
            <Button variant="outlined" style={{ marginLeft: 16 }} onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </Box>
        </MuiPickersUtilsProvider>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.row}>
                <StyledTableCell />
                <StyledTableCell align="right">Started</StyledTableCell>
                <StyledTableCell align="right">Updated</StyledTableCell>
                <StyledTableCell align="right">Channel</StyledTableCell>
                <StyledTableCell align="right">File Name</StyledTableCell>
                <StyledTableCell align="right">Analysis Status</StyledTableCell>
                <StyledTableCell align="right">rPeaks</StyledTableCell>
                <StyledTableCell align="right">Comments</StyledTableCell>
                <StyledTableCell align="right">
                  <Box className={classes.flagCell}>
                    <Typography className={classes.flagText} variant="body1">
                      Flags
                    </Typography>
                    <Tooltip title="Filter list">
                      <IconButton
                        color={search ? 'secondary' : 'inherit'}
                        aria-label="filter list"
                        onClick={handleClick}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={popoverId}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={handleClose}
                    >
                      <FormControl className={classes.formControl}>
                        <Select
                          value={search}
                          inputProps={{
                            'aria-label': 'Without label',
                          }}
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {flagOptions.map((it) => (
                            <MenuItem key={it} value={it}>
                              {it}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Popover>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="right">Download Annotations</StyledTableCell>
                <StyledTableCell align="right">Download File</StyledTableCell>
                <StyledTableCell align="right">Reanalyze</StyledTableCell>
                <StyledTableCell align="right">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecordings.map(
                ({
                  fileName,
                  id,
                  recordingStartedAt,
                  metadata,
                  analysis,
                  channelCount,
                  comment,
                  flags,
                }) => (
                  <RecordingItem
                    key={id}
                    recordingId={id}
                    analysis={analysis}
                    channelCount={channelCount}
                    comment={comment}
                    fileName={fileName}
                    flagOptions={flagOptions}
                    flags={flags ?? []}
                    updatedAt={metadata?.updatedAt}
                    recordingStartedAt={recordingStartedAt}
                    onDelete={deleteRecording}
                    onReanalyze={reanalyze}
                  />
                ),
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: totalCount }]}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  ActionsComponent={TablePaginationActions}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
