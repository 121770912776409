import { useMemo } from 'react';
import UPlotReact from 'uplot-react';
import { format } from 'date-fns';
import $ from 'jquery';
import uPlot from 'uplot';
import { useTranslation } from 'react-i18next';

import { buildIntervalIndicator } from 'utilities';
import { chunkArray } from '../utils/chunkArray';
import { formatDateTimeRange, formatTimeDuration } from '../utils/formatTime';
import { createEpisodeContentSeries } from '../utils/createEpisodeContentSeries';
import { shouldRenderChartAnnotation } from '../utils/shouldRenderChartAnnotation';

type AFibChartProps = {
  recordingStartedAt: number;
  startIndex: number;
  endIndex: number;
  signal: number[];
  rpeaks: number[];
  showTitle?: boolean;
};

export function AFibChart({
  recordingStartedAt,
  startIndex,
  endIndex,
  signal,
  rpeaks,
  showTitle = true,
}: AFibChartProps) {
  const { t } = useTranslation();
  const dateRange = formatDateTimeRange({
    fromMiliseconds: recordingStartedAt + (startIndex / 200) * 1000,
    toMiliseconds: recordingStartedAt + (endIndex / 200) * 1000,
    showSeconds: true,
  });
  const duration = formatTimeDuration({
    durationInMilliseconds: (endIndex - startIndex) * 5,
    showSeconds: true,
  });

  const rpeakIndexesMap = rpeaks.reduce((acc, rpeak) => {
    acc[rpeak] = true;

    return acc;
  }, {} as Record<number, boolean>);

  const episodeLength = endIndex - startIndex;
  const maxSignalLength = 70 * 200; // 70 seconds

  let xAxis: number[] = [];

  if (episodeLength > maxSignalLength) {
    const xAxisStart = Array.from({ length: signal.length / 2 }, (_, i) => startIndex - 1000 + i);
    const xAxisEnd = Array.from(
      { length: signal.length / 2 },
      (_, i) => endIndex - maxSignalLength / 2 + i,
    );

    xAxis = [...xAxisStart, ...xAxisEnd];
  } else {
    xAxis = Array.from({ length: signal.length }, (_, i) => startIndex - 1000 + i);
  }

  const rpeaksIndexes = useMemo(
    () => xAxis.map((x) => (rpeakIndexesMap[x] ? x : null)),
    [xAxis, rpeakIndexesMap],
  );

  const episodeContentTopSeries = useMemo(
    () => createEpisodeContentSeries({ xAxis, start: startIndex, end: endIndex, yAxisValue: 2 }),
    [xAxis, startIndex, endIndex],
  );
  const episodeContentBottomSeries = useMemo(
    () => createEpisodeContentSeries({ xAxis, start: startIndex, end: endIndex, yAxisValue: -2 }),
    [xAxis, startIndex, endIndex],
  );

  const signalChunks = useMemo(() => chunkArray(signal), [signal]);
  const xAxisChunks = useMemo(() => chunkArray(xAxis), [xAxis]);
  const episodeContentTopChunks = useMemo(
    () => chunkArray(episodeContentTopSeries),
    [episodeContentTopSeries],
  );
  const episodeContentBottomChunks = useMemo(
    () => chunkArray(episodeContentBottomSeries),
    [episodeContentBottomSeries],
  );
  const rpeaksChunks = useMemo(() => chunkArray(rpeaksIndexes), [rpeaksIndexes]);

  return (
    <>
      {signalChunks.map((chunk, index) => {
        if (index === signalChunks.length - 1 && !xAxisChunks[index].includes(endIndex)) {
          return null;
        }

        const options = {
          title:
            index === 0 && showTitle ? `${t('afibChart.chartTitle', { dateRange, duration })}` : '',
          width: 700, // A4 format - 210mm == 800px
          height: 220,
          series: [
            {
              label: t('afibChart.seriesTimeLabel'),
              value: (_, rawValue) =>
                format(recordingStartedAt + (rawValue / 200) * 1000, 'yyy-MM-dd H:mm:ss'),
            },
            { label: t('afibChart.seriesVoltageLabel'), stroke: 'red' },
            { fill: 'rgba(128, 128, 128, 0.2)' },
            { fill: 'rgba(128, 128, 128, 0.2)' },
          ],
          axes: [
            {
              values: (_, splits) =>
                splits.map((idx) => format(recordingStartedAt + (idx / 200) * 1000, 'H:mm:ss')),
            },
            { label: t('afibChart.axisVoltageLabel') },
          ],
          hooks: {
            drawAxes: [
              (u: uPlot) => {
                let previousSampleIndex;

                rpeaksChunks[index].forEach((currentSampleValue, idx, arr) => {
                  if (currentSampleValue === null) return;

                  if (!previousSampleIndex) {
                    previousSampleIndex = idx;

                    return;
                  }

                  // currentSampleIndex is the index of the sample in the chunk
                  const currentAnnotationVoltage = u.data[1][idx] as number;
                  const currentSamplePosX = Math.round(
                    u.valToPos(currentSampleValue as number, 'x', false),
                  );
                  const currentSamplePosY = Math.round(
                    u.valToPos(currentAnnotationVoltage, 'y', false),
                  );

                  // previousSampleIndex is the index of the sample in the chunk
                  const previousAnnotationVoltage = u.data[1][previousSampleIndex] as number;
                  const previousSampleValue = arr[previousSampleIndex] as number;
                  const previousSamplePosX = Math.round(
                    u.valToPos(previousSampleValue, 'x', false),
                  );
                  const previousSamplePosY = Math.round(
                    u.valToPos(previousAnnotationVoltage, 'y', false),
                  );

                  const shouldRenderAnnotation = shouldRenderChartAnnotation(
                    previousAnnotationVoltage,
                    currentAnnotationVoltage,
                  );

                  if (shouldRenderAnnotation) {
                    $(u.root.querySelector('.u-over')).append(
                      buildIntervalIndicator({
                        fromX: previousSamplePosX,
                        fromY: previousSamplePosY,
                        toX: currentSamplePosX,
                        toY: currentSamplePosY,
                        content: t('afibChart.chartAnnotationContent', {
                          intervalDuration: (currentSampleValue - previousSampleValue) / 200,
                        }),
                      }),
                    );
                  }

                  previousSampleIndex = idx;
                });
              },
            ],
          },
          plugins: [],
          legend: { show: false },
          scales: { x: { time: true }, y: { range: [-2, 3] } },
        };

        return (
          <div key={`${index + 1}`}>
            <UPlotReact
              options={options as any}
              data={[
                xAxisChunks[index] as number[],
                chunk,
                episodeContentTopChunks[index],
                episodeContentBottomChunks[index],
              ]}
            />
          </div>
        );
      })}
    </>
  );
}
